@import "variables";
@import "bootstrap/scss/bootstrap";

@import url(https://fonts.googleapis.com/css?family=Quicksand:400,600);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,600);
@import url(https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap);

@font-face {
	font-family: "color-emoji";
	src: local("Apple Color Emoji"), local("Segoe UI Emoji"), local("Segoe UI Symbol"), local("Noto Color Emoji");
}

$grey: #777;
$baseline: 1.75em;

html,
body {
	font-family: 'Quicksand', sans-serif, color-emoji;
	font-size: $custom-font-size;
	background-color: #999;
	min-height: 100vh;
		background: linear-gradient(to right, transparent, #ddd 0%, rgba(250, 250, 250, 0) 9%, rgba(250, 250, 250, 0) 91%, #ddd 100%),
		gray repeating-linear-gradient(0deg, #fbfbfb, #fbfbfb 4px,
			rgb(245, 245, 250) 4px,
			rgb(245, 245, 245) 8px);
}

#invert {
	position: absolute;
	top: 2em;
	z-index: -1;
	opacity: 0;
}

nav.navbar {
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.container.rounded {
	margin-top: 2.5rem;
	min-height: calc(100vh - 47px - 4rem);
	transition: all .13s ease;
	background-color: #f6f6f6;
	//padding-top: 1.5rem;
	text-shadow: 0 1px #ccc;
	box-shadow: 0 0 1em rgba(0, 0, 0, 0.4);


	background: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgb(255, 255, 255);
	padding-bottom: 1rem;
}

.container #myCarrousel img {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

// .container h2 {
// 	text-shadow: 0 1px #333;
// 	color: #fff;
// }

.container .btn,
.container .badge {
	text-shadow: none;
}

.badge{
&.category {
	font-weight: bold;
} &.tag {
	font-weight: normal;
}}

#invert:checked+.container {
	background: #121212;
	box-shadow: 0 0 0 6.5em #111;
	color: #dcdcdc;
	text-shadow: none;

	& .card {
		background: #121212;
	}

	& a {
		color: #706BF5;
	}

	& *-primary {
		background-color: #706BF5;
	}
}

#invert:checked+footer {
	background: #121212;
	color: #dcdcdc;
}


.container p {
	text-align: justify;
}

article {
	font-family: 'PT Serif', sans-serif;

	& img {
		max-width: 100%;
		filter: none !important;
	}
}

label[for="invert"] {
	right: 11em;
	width: 6em;
}




@for $i from 1 through 6 {
	h#{$i} {
		//font-size: $base-font-size + $heading-scale * (6 - $i);
		font-family: 'Raleway', sans-serif;
	}
}

h2 {
	margin-top: 2rem;
	margin-bottom: 2rem;
	letter-spacing: 0.25rem;
	position: relative;
}

h2:before {
	content: '';
	border-bottom: solid 2px #1405AF;
	width: 7.5rem;
	display: block;
	left: 50%;
	right: 50%;
	bottom: -10px;
	position: absolute;
	transform: translate(-50%,-50%);
}

.card-read-more {
	background-color: var(--bs-card-cap-bg);
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: solid 2px #fff;
	
	& .btn {
		border-radius: 0;
		border-top: none;
		border-bottom: none;
	}
}

.card-footer {
	border: none;
}

@media (max-width: 475px) {

	body,
	html {
		padding-bottom: 2rem;
		margin-bottom: 2rem;
	}

	.container {
		margin-top: 0.5rem;
	}

	.container.rounded {
		margin-top: 0;
		padding-top: 0;
	}
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

img.avatar {
	width: 200px;
	height: 200px;
	object-fit: cover;
}

.social {
	.btn {
		border: $grey;
	}

	.whatsapp {
		background: #25d366;
	}

	.facebook {
		background: #3b5998;
	}

	.twitter {
		background: #1DA1F2;
	}

	.reddit {
		background: #FF5700
	}

}

.btn {
	border: solid;

	&.whatsapp {
		border-color: #25d366;
	}

	&.facebook {
		border-color: #3b5998;
	}

	&.twitter {
		border-color: #1DA1F2;
	}

	&.reddit {
		border-color: #FF5700
	}

	&.instagram {
		border-color: #d6249f;
	}
}
